import {
  afterRender,
  Inject,
  Injectable,
  Optional,
  REQUEST,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
  ISbRichtext,
  ISbStories,
  ISbStoriesParams,
  ISbStory,
} from '@storyblok/js';
import { Request as ExpressRequest } from 'express';
import { redirects } from 'redirects';
import { ReplaySubject } from 'rxjs';
import Client from 'storyblok-js-client';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class StoryBlokService {
  lang = this.environmentService.environmentLocale;
  environment = this.environmentService.environment;
  private themeSubject = new ReplaySubject<any>(1);
  public themeObservable = this.themeSubject.asObservable();
  private sbClient = new Client({
    accessToken: this.environment.storyBlokKey,
    region: 'ap',
  });

  _storyblok: boolean = false;
  constructor(
    @Optional() @Inject(REQUEST) private req: ExpressRequest,
    private sanitiser: DomSanitizer,
    private route: ActivatedRoute,

    private environmentService: EnvironmentService
  ) {
    if (this.req?.url?.includes('_storyblok')) {
      this._storyblok = true;
    }
    afterRender(() => {
      if (window && window?.location.href.includes('_storyblok')) {
        this._storyblok = true;
      }
    });
  }

  async getTheme() {
    return await this.getStory('theme-settings', {
      version:
        this.environment.production && !this._storyblok ? 'published' : 'draft',
      language: this.lang,
    }).then((data) => {
      this.themeSubject.next(data.story);
      return this.themeObservable;
    });
  }

  getStory(slug: string, params: ISbStoriesParams = {}) {
    return this.sbClient
      .getStory(slug, {
        ...params,
        resolve_links: 'link',
        version:
          this.environment.production && !this._storyblok
            ? 'published'
            : 'draft',
        language: this.lang,
      })
      .then((res: ISbStory) => res.data);
  }

  getStories(params: ISbStoriesParams) {
    return this.sbClient
      .getStories({
        ...params,
        resolve_links: 'link',
        version:
          this.environment.production && !this._storyblok
            ? 'published'
            : 'draft',
        language: this.lang,
      })
      .then((res: ISbStories) => res.data);
  }

  normalizeUrl(url: string) {
    // Remove leading/trailing whitespace
    if (!url) return '';
    url = url?.trim();

    // Check if the URL already has a protocol
    if (!/^[a-z]+:\/\//i.test(url)) {
      // If no protocol, prepend the default protocol
      url = `https://${url}`;
    }

    try {
      // Attempt to create a URL object to validate and normalize the URL
      const urlObject = new URL(url);
      return urlObject.toString();
    } catch (error) {
      console.log(error);
      // If URL is invalid, return null or handle the error as needed
      console.error('Invalid URL:', url);
      return null;
    }
  }

  transform(value: string): string {
    // Replace opening tags
    value = value.replace(/<h([1-6])([^>]*)>/gi, (match, level, attributes) => {
      return `<div class="h${level}"${attributes}>`;
    });

    // Replace closing tags
    value = value.replace(/<\/h([1-6])>/gi, '</div>');

    return value;
  }

  renderRichText(field: ISbRichtext) {
    if (typeof field === 'string') {
      return field;
    }

    try {
      if (field.content && field.content.length > 0) {
        for (let entry of field.content) {
          if (entry?.content) {
            const targets = entry.content.filter((x) =>
              x.marks?.find((x) => x.type === 'link')
            );
            for (let link of targets) {
              if (!link.marks) continue;
              for (let sub of link.marks.filter((x) => x.type == 'link')) {
                if (sub.attrs?.href) {
                  console.log(sub.attrs);
                  const normalizedHref = this.normalizeUrl(sub.attrs.href);
                  if (
                    normalizedHref &&
                    normalizedHref.startsWith(this.environment.siteUrl)
                  ) {
                    sub.attrs.href = normalizedHref;
                  }
                  const redirect = redirects.find(
                    (x: any) =>
                      x.from ==
                      new URL(sub.attrs.href, this.environment.siteUrl).pathname
                  ) as any;
                  if (redirect) {
                    sub.attrs.href = redirect.to.startsWith('/')
                      ? redirect.to.slice(1)
                      : redirect.to;
                  }
                }
              }
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

    const result = this.transform(this.sbClient.richTextResolver.render(field));
    if (result == '<p></p>') {
      return this.sanitiser.bypassSecurityTrustHtml('');
    }
    return this.sanitiser.bypassSecurityTrustHtml(result);
  }
}
